import { Config } from '../../interfaces/config.interface';
import Logo from './logo.png';

const config: Config = {
  name: 'Alma Cívica',
  title: 'Actividades de voluntariado',
  logo: Logo,
  map_hide: true,
  theme: {
    primary: '#8C1D80',
    secondary: '#34BFA3',
  },
  withoutLogin: true,
};

export default config;
